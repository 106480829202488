import React from 'react';

function SectionOfferingsTitle({ offeringTitles = [], sessionOfferings }) {
  if (sessionOfferings.length === 0) {
    return null;
  }
  return (
    <div className="healerProfileSection">
      <h3>Offerings</h3>
      {/* <ul className="tags">
        {offeringTitles.map((offeringTitle, index) => (
          <li key={index}>{offeringTitle}</li>
        ))}
      </ul> */}
      <ul className="tags">
        {sessionOfferings.map((offeringTitle, index) => (
          <li key={index}>{offeringTitle.label}</li>
        ))}
      </ul>
    </div>
  );
}

export default SectionOfferingsTitle;
