import React from 'react';
import config from '../../config';

function SectionLanguagesSpoken({ languagesSpoken, intl }) {
  return (
    languagesSpoken && (
      <div className="healerProfileSection">
        <h3> {intl.formatMessage({ id: 'ListingPage.SectionLanguagesSpoken.title' })}</h3>
        {Array.isArray(languagesSpoken) ? (
          <p>
            {languagesSpoken
              .map(v => config.custom.languages.find(l => l.key == v)?.label)
              .join(', ')}
          </p>
        ) : (
          <p>{languagesSpoken}</p>
        )}
      </div>
    )
  );
}

export default SectionLanguagesSpoken;
