import React from 'react';
import css from './ListingPage.module.css';

function SectionAccrediations({ title, accrediations }) {
  return accrediations ? (
    <div className="healerProfileSection">
      {/* <h3>{title} Accredidations</h3> */}
      <h3>Active &amp; Applicable Accreditations</h3>
      {/* <ul style={{ margin: 0, padding: 0 }}> */}
      {accrediations && <p className={css.accrediations}>{accrediations}</p>}
      {/* </ul> */}
    </div>
  ) : null;
}

export default SectionAccrediations;
