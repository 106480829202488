import React from 'react';
import { injectIntl } from '../../util/reactIntl';

function SectionCountryOfOrigin({ country, intl }) {
  return (
    country && (
      <div className="healerProfileSection">
        <h3>{intl.formatMessage({ id: 'ListingPage.SectionCountryOfOrigin.title' })}</h3>
        {country}
      </div>
    )
  );
}

export default injectIntl(SectionCountryOfOrigin);
