import React from 'react';

function SectionBusinessName({ title, businessName, intl }) {
  return businessName ? (
    <div className="healerProfileSection">
      <h3>{intl.formatMessage({ id: 'ListingPage.SectionBusinessName.title' })}</h3>

      <p>Of {businessName}</p>
    </div>
  ) : null;
}

export default SectionBusinessName;
