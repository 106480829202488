import React from 'react';
import css from './ListingPage.module.css';

function SectionAbout({ title, description }) {
  return (
    <div className="healerProfileSection">
      <h3>About {title}</h3>
      <p className={css.about}>{description}</p>
    </div>
  );
}

export default SectionAbout;
