import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  IconSpinner,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';

import {
  sendEnquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  fetchListingId,
  removeListing,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.module.css';

import { Parallax, Background } from 'react-parallax';
// import 'react-bnb-gallery/dist/style.css';
import ReactBnbGallery from 'react-bnb-gallery';
import SectionAbout from './SectionAbout';
import SectionProfileIdentities from './SectionProfileIdentities';
import SectionAccrediations from './SectionAccrediations';
import SectionLanguagesSpoken from './SectionLanguagesSpoken';
import SectionOfferingsTitle from './SectionOfferingsTitle';
import SectionOfferings from './SectionOfferings';
import { createReview, createUser } from '../../util/test-data';
import ActionBarMaybe from './ActionBarMaybe';
import SectionBusinessName from './SectionBusinessName';
import classNames from 'classnames';
import SectionCountryOfOrigin from './SectionCountryOfOrigin';
import LoadingPage from '../LoadingPage/LoadingPage';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      open: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      listingId: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingStartTime, bookingEndTime, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);

    const bookingData = {
      quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      ...restOfValues,
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }
  componentWillUnmount() {
    //remove listing data from store
    this.props.onUnloadListing();
  }
  // async componentDidMount() {
  //   if (this.props.params.listingTitle) {
  //     try {
  //       const res = await this.props.fetchListingIdData(this.props.params);
  //       const listingId = res.uuid;
  //       this.setState({ listingId });
  //     } catch (e) {
  //       this.props.history.push('/notfound');
  //       console.log('listing page', e);
  //     }
  //   }
  // }
  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      slugListingId,
      history,
      fetchListingInProgress,
    } = this.props;
    // const reviews = [
    //   createReview('review_1', { rating: 1 }, { author: createUser('author_1') }),
    //   createReview('review_2', { rating: 3 }, { author: createUser('author_2') }),
    //   createReview('review_3', { rating: 5 }, { author: createUser('author_3') }),
    // ];
    if (fetchListingInProgress) {
      return <LoadingPage />;
    }
    const variant = history.location.pathname.split('/')[2];

    const listingId = new UUID(slugListingId || rawParams.id);
    const isPendingApprovalVariant =
      rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT ||
      variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant =
      rawParams.variant === LISTING_PAGE_DRAFT_VARIANT || variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData = {},
      metadata = {},
    } = currentListing.attributes;
    if (!currentListing) return null;
    if (metadata?.listingTitle && history?.location?.pathname.includes('/l/')) {
      console.log('listing page', metadata.listingTitle);
      return (
        <NamedRedirect
          name="FetchListing"
          params={{ listingTitle: metadata.listingTitle, variant: rawParams?.variant }}
        />
      );
    }
    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found
      // console.log('listing page', showListingError);
      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleOwnOrClosedListingClick = () => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      }
    };
    // const handleBookingSubmit = values => {
    //   const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    //   if (isOwnListing || isCurrentlyClosed) {
    //     window.scrollTo(0, 0);
    //   } else {
    //     this.handleSubmit(values);
    //   }
    // };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const yogaStylesOptions = findOptionsForSelectFilter('yogaStyles', filterConfig);
    const certificateOptions = findOptionsForSelectFilter('certificate', filterConfig);

    const hasImages = currentListing.images && currentListing.images.length > 0;
    const firstImage = hasImages ? currentListing.images[0] : null;

    const firstImageUrl = firstImage
      ? firstImage?.attributes?.variants?.['landscape-crop']?.url
      : null;

    const PHOTOS = (currentListing.images ?? []).map(v => v.attributes?.variants?.['default']?.url);
    // console.log('hi', currentListing.images);
    const address = publicData?.location?.address;
    const featuredIdentities = publicData?.featured_identities;
    const offerings = publicData?.offerings ?? [];

    let allIdentities = [
      ...(publicData?.background_identities ?? []),
      ...(publicData?.disability_identities ?? []),
      ...(publicData?.gender_identities ?? []),
      ...(publicData?.misc_identities ?? []),
      ...(publicData?.sexuality_identities ?? []),
      ...(publicData?.spirituality_identities ?? []),
    ];

    allIdentities = allIdentities
      .map(l => config.custom.allIdentities.find(k => k.key == l))
      .filter(Boolean);
    const healerFeaturedIdentities =
      publicData.featuredIdentities?.map(k => config.custom.allIdentities.find(l => l.key == k)) ??
      [];

    const featuredIdentitiesList = (featuredIdentities ?? [])?.map(identity =>
      config.custom.allIdentities.find(f => f.key == identity)
    );

    const offeringTitles = offerings.map(offering => offering.offering_title);
    const sessionOfferings = publicData?.modalities
      ? publicData?.modalities
          ?.map(m => config.custom.allModalities?.find(o => o.key == m))
          .filter(Boolean)
      : [];

    const accrediations = publicData?.accrediations ?? null;
    const businessName = publicData?.business_name ?? null;
    const getMoney = amount => {
      // console.log({ amount });
      return formatMoney(intl, new Money(amount, config.currency));
    };

    const languagesSpoken = publicData?.languages_spoken ?? null;

    const country = publicData?.country_of_origin?.label ?? null;

    const editParams = {
      id: listingId.uuid,
      slug: listingSlug,
      type: listingType,
      tab: listingTab,
    };

    const actionBar = currentListing.id ? (
      <div>
        <ActionBarMaybe
          isOwnListing={isOwnListing}
          listing={currentListing}
          editParams={editParams}
        />
      </div>
    ) : null;

    let pronouns = publicData?.pronouns;

    const pronounsData =
      publicData?.pronouns_option == 'pronouns_other'
        ? publicData?.other_pronouns == 'all_pronouns'
          ? 'All Pronouns'
          : publicData?.other_pronouns == 'no_pronouns'
          ? 'No Pronouns'
          : null
        : publicData?.pronouns_option == 'display_my_pronouns'
        ? (pronouns ?? [])
            ?.map(p => config.custom.pronouns.find(pn => pn.key == p)?.label)
            .join('/')
        : null;

    // pronouns = showPronouns ? config.custom.pronouns.find(p => p.key == pronouns)?.label : null;
    const customFirstImage = publicData?.customBanner
      ? config.custom.bannerImages?.find(b => b.id === publicData?.customBanner)?.image
      : publicData?.bannerImage || firstImage;
    // console.log({ customFirstImage });
    const { author } = currentListing || {};
    const { attributes: authorAttributes } = author || {};
    const { profile: authorProfile } = authorAttributes || {};
    const { metadata: authorMetadata } = authorProfile || {};
    const { membership } = authorMetadata || {};
    const platformURL = currentListing?.attributes?.metadata?.booking_platform_website;
    const clickMaybe =
      currentListing?.attributes?.metadata?.ownBookingPlatform && platformURL && membership
        ? {
            onClick: e => {
              e.preventDefault();
              window.open(platformURL, '_blank');
            },
          }
        : {};

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {actionBar}
            {/* <div>
              <SectionImages
                title={title}
                listing={currentListing}
                isOwnListing={isOwnListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                }}
                imageCarouselOpen={this.state.imageCarouselOpen}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                handleViewPhotosClick={handleViewPhotosClick}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <div className={css.contentContainer}>
                <SectionAvatar user={currentAuthor} params={params} />
                <div className={css.mainContent}>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    listingCertificate={publicData ? publicData.certificate : null}
                    certificateOptions={certificateOptions}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                  />
                  <SectionDescriptionMaybe description={description} />
                  <SectionFeaturesMaybe options={yogaStylesOptions} publicData={publicData} />
                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                  <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
                </div>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                />
              </div>
            </div> */}
            <div className="container-full hMain">
              {PHOTOS.length > 0 || customFirstImage ? (
                <>
                  <div className="healerMainProfileHeader">
                    <Parallax
                      blur={1}
                      // bgStyle={{
                      //   height: '100% !important',
                      // }}
                      bgImage={customFirstImage || firstImage}
                      className="healerImg"
                      bgImageAlt="Queer Healers"
                      strength={200}
                      renderLayer={percentage => (
                        <div
                          style={{
                            position: 'absolute',
                            background: `rgba(0,0,0,.4)`,
                            left: '0%',
                            top: '0%',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      )}
                    >
                      <div className="healerProfileContent">
                        <div className="healerInfo">
                          <ul>
                            {featuredIdentitiesList.map((l, index) => (
                              <li key={`${l.key}-${index}`}>{l.label} &middot;</li>
                            ))}
                          </ul>
                          <ul>
                            {healerFeaturedIdentities.map((l, index) => (
                              <li key={`${l.key}-${index}`}>
                                {l.label}{' '}
                                {index == healerFeaturedIdentities.length - 1 ? null : '·'}
                              </li>
                            ))}
                          </ul>

                          <h1 className={css.listingTitle}>
                            {title}{' '}
                            {publicData?.post_nominal_letters && (
                              <small className={css.postNominal}>
                                {publicData?.post_nominal_letters}
                              </small>
                            )}
                          </h1>
                          {publicData?.business_name && (
                            <>
                              <small className={css.business_name}>
                                Of {publicData?.business_name}
                              </small>{' '}
                              <br />
                            </>
                          )}

                          {/* {showPronouns && (
                            <>
                              <small className={css.pronouns}>{pronouns}</small> <br />
                            </>
                          )} */}
                          {pronounsData && (
                            <>
                              <small className={css.pronouns}>{pronounsData}</small> <br />
                            </>
                          )}

                          <ul>
                            <li>{address}</li>
                          </ul>
                        </div>
                        {PHOTOS?.length ? (
                          <div className={classNames('healerProfileButtons', css.flexify)}>
                            <button
                              type="button"
                              className="btn greyOutline"
                              onClick={() => this.setState({ open: true })}
                            >
                              {intl.formatMessage({ id: 'ListingPage.viewMorePhotosButton' })}
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </Parallax>
                  </div>
                  <ReactBnbGallery
                    show={this.state.open}
                    photos={PHOTOS}
                    onClose={() => this.setState({ open: false })}
                  />
                </>
              ) : null}

              <div className="container push-top-large">
                <div className="row">
                  <div className="col-md-8 mx-8">
                    <SectionOfferingsTitle
                      offeringTitles={offeringTitles}
                      sessionOfferings={sessionOfferings}
                    />
                    <SectionAbout description={description} title={title} />
                    <SectionProfileIdentities title={title} allIdentities={allIdentities} />
                    <SectionAccrediations title={title} accrediations={accrediations} />

                    {false && (
                      <div className="healerProfileSection">
                        <h3>
                          {intl.formatMessage(
                            { id: 'ListingPage.profileInformationHeader' },
                            { title: title }
                          )}
                        </h3>
                        <p>{intl.formatMessage({ id: 'ListingPage.profileInformationBody' })}</p>
                      </div>
                    )}

                    <SectionCountryOfOrigin country={country} />

                    <SectionLanguagesSpoken intl={intl} languagesSpoken={languagesSpoken} />
                    <SectionBusinessName title={title} intl={intl} businessName={businessName} />
                    <div className={css.padBottom}>
                      <SectionMapMaybe
                        geolocation={geolocation}
                        publicData={publicData}
                        listingId={currentListing.id}
                      />
                    </div>

                    <div className="healerProfileSection">
                      <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
                      {/* <div className="reviewList">
                        <div className="review">
                          <div>
                            <img src={firstImageUrl} alt="Queer Healers" />
                          </div>
                          <div className="reviewContent">
                            <h4>Claarisa Hall • New York, NY</h4>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's standard dummy text ever
                              since the 1500s, when an unknown printer took a galley of type and
                              scrambled it to make a type specimen book. It has survived not only
                              five centuries, but also the leap into electronic typesetting,
                              remaining essentially unchanged. It was popularised in the 1960s with
                              the release of Letraset sheets containing Lorem Ipsum passages, and
                              more recently with desktop publishing software like Aldus PageMaker
                              including versions of Lorem Ipsum.
                            </p>
                          </div>
                        </div>
                        <div className="review">
                          <div>
                            <img src={firstImageUrl} alt="Queer Healers" />
                          </div>
                          <div className="reviewContent">
                            <h4>Jake Lu • New York, NY</h4>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's standard dummy text ever
                              since the 1500s, when an unknown printer took a galley of type and
                              scrambled.
                            </p>
                          </div>
                        </div>
                        <div className="review">
                          <div>
                            <img src={firstImageUrl} alt="Queer Healers" />
                          </div>
                          <div className="reviewContent">
                            <h4>Mary Peters • New York, NY</h4>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's standard dummy text ever
                              since the 1500s.
                            </p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <SectionOfferings
                    offerings={offerings}
                    getMoney={getMoney}
                    currentListing={currentListing}
                    handleOwnOrClosedListingClick={handleOwnOrClosedListingClick}
                    isOwnListing={isOwnListing}
                    intl={intl}
                    clickMaybe={clickMaybe}
                  />
                </div>
              </div>
            </div>
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    slugListingId,
    fetchListingInProgress,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    slugListingId,
    fetchListingInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  fetchListingIdData: params => dispatch(fetchListingId(params)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onUnloadListing: () => dispatch(removeListing()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
