import React from 'react';

function SectionProfileIdentities({ title, allIdentities = [] }) {
  if (allIdentities.length == 0) return null;
  return (
    <div className="healerProfileSection">
      {/* <h3>{title} Identites</h3> */}
      <h3>Identities</h3>
      <ul className="tags">
        {allIdentities.map((l, index) => (
          <li key={`${l.key}-${index}`}>{l.label}</li>
        ))}
      </ul>
    </div>
  );
}

export default SectionProfileIdentities;
