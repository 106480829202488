import React from 'react';
import { ExternalLink, NamedLink } from '../../components';
import { getPriceWithComission } from '../../util/functions';
import css from './ListingPage.module.css';

function SectionOfferings({
  offerings = [],
  getMoney,
  currentListing,
  handleOwnOrClosedListingClick,
  isOwnListing,
  intl,
  clickMaybe,
}) {
  if (offerings.length == 0) {
    return null;
  }
  // console.log({ offerings });
  const handleOwnListingClick = evt => {
    evt.preventDefault();
    handleOwnOrClosedListingClick();
  };
  const isVirtualSession = item => {
    return (
      item?.session_location?.includes('phone') ||
      item?.session_location?.includes('text') ||
      item?.session_location?.includes('video')
    );
  };
  return (
    <div className="col-md-4 mx-8">
      <div className="healerProfileOfferings">
        <div className="header">
          <h3>{intl.formatMessage({ id: 'ListingPage.SectionOfferings.title' })}</h3>
        </div>
        <ul>
          {offerings
            .map(o => ({ ...o, offering_price: getPriceWithComission(o.offering_price) }))
            .map((o, index) => (
              <li key={`${index}`}>
                <div className="offerringInfo">
                  <h4>{o.offering_title}</h4>
                  {o.offering_price && (
                    <p className="price">
                      {getMoney(o.offering_price)}
                      {isVirtualSession(o) ? (
                        <span className={css.virtualSession}>
                          -{' '}
                          {intl.formatMessage({
                            id: 'ListingPage.SectionOfferings.virtualSession',
                          })}
                        </span>
                      ) : null}
                    </p>
                  )}

                  <p className={css.leadingNormal}>{o.offering_description}</p>
                  <p>
                    <time className={css.time}>
                      {intl.formatMessage(
                        { id: 'ListingPage.SectionOfferings.offeringTimeText' },
                        { booking_time_availability: o.booking_time_availability }
                      )}
                    </time>
                  </p>
                </div>
                <div>
                  {isOwnListing ? (
                    <a href={'#'} onClick={handleOwnListingClick} className="btn btn-small">
                      {intl.formatMessage({
                        id: 'ListingPage.SectionOfferings.bookNowButtonLabel',
                      })}
                    </a>
                  ) : (
                    <NamedLink
                      className="btn btn-small"
                      name="BookingPage"
                      params={{
                        id: currentListing.id.uuid,
                      }}
                      to={{
                        state: {
                          selectedOffer: index,
                        },
                      }}
                      {...clickMaybe}
                    >
                      {intl.formatMessage({
                        id: 'ListingPage.SectionOfferings.bookNowButtonLabel',
                      })}
                    </NamedLink>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className={css.reportAbuseLink}>
        <ExternalLink href="https://airtable.com/shrfhcSgPiifg4lzG" className={css.reportLink}>
          {intl.formatMessage({ id: 'ListingPage.SectionOfferings.reportAbuseLink' })}
        </ExternalLink>
      </div>
    </div>
  );
}

export default SectionOfferings;
